import {
  Avatar,
  Badge,
  Box,
  Divider,
  HStack,
  Image,
  Progress,
  Spinner,
  Stack,
  Text,
  VStack,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { TicketAction, TicketItem, TicketStatus, TxHashType } from "../types";
import KeyValuePair from "src/components/KeyValuePair";
import { gql } from "graphql-request";
import { fetchGraph } from "../utils/graphFetch";
import HashLink from "src/components/HashLink";
import dayjs from "dayjs";
import {
  beautifyAddress,
  beautifyTokenId,
  formatTickets,
} from "src/utils/format";
import {
  MD_WIDTH,
  PROTOCOL_COLORS,
  TICKET_ACTION_COLORS,
  TICKET_ACTION_LABEL,
} from "src/utils/constants";
import { useMetadata } from "src/contexts/metadata";
import TicketStatusComp from "src/components/TicketStatusComp";
import { ChainID } from "@omnity/widget/src/types";
import LOGO from "../assets/logo.svg";
import ServiceFactory from "@omnity/widget/src/services/Factory";
import { ArrowUpRight } from "lucide-react";
import { Helmet } from "react-helmet";
import { readableNumber } from "@omnity/widget/src/utils/format";
import IChainLogo from "src/components/IChainLogo";
import { checkConfirmations, getAssetProtocol } from "src/utils/chains";
import ReactJson from "react-json-view";

async function fetchTicket(id: string) {
  try {
    const doc = gql`
      {
        ticket(
          where: {
            ticket_id: {
              _eq: "${id}"
            }
          }
        ) {
          action
          amount
          dst_chain
          memo
          receiver
          sender
          src_chain
          status
          ticket_id
          ticket_seq
          ticket_time
          ticket_type
          token
          tx_hash
          intermediate_tx_hash
        }
      }
    `;
    const data = await fetchGraph(doc, {});
    return formatTickets(data.ticket)[0];
  } catch (error) {
    return null;
  }
}

export default function Ticket() {
  const [ticket, setTicket] = useState<TicketItem | null>();
  const [confirmation, setConfirmation] = useState<{
    txHashType: TxHashType;
    confirmations: number;
  }>();
  const {
    isOpen: isLoading,
    onOpen,
    onClose,
  } = useDisclosure({ defaultIsOpen: true });
  const params = useParams();
  const ticketId = params.id ?? "";

  const { formatTokenAmount } = useMetadata();
  let token;
  if (ticket) {
    token = formatTokenAmount?.(ticket.amount, ticket.token, false);
  }

  useEffect(() => {
    if (ticketId) {
      onOpen();
      fetchTicket(ticketId).then((data) => {
        setTicket(data);
        onClose();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId]);

  const maxW = window.outerWidth - 60;

  useEffect(() => {
    let tx_hash;
    let txHashType = TxHashType.Src;
    if (ticket?.action === TicketAction.Mint) {
      tx_hash = ticket?.intermediate_tx_hash;
      txHashType = TxHashType.Intermediate;
    } else if (ticket?.action === TicketAction.Burn) {
      tx_hash = ticket?.tx_hash;
      txHashType = TxHashType.Dst;
    } else if (ticket?.action === TicketAction.Transfer) {
      if (ticket.src_chain === ChainID.Bitcoin) {
        tx_hash = ticket.ticket_id;
        txHashType = TxHashType.Src;
      } else if (ticket.dst_chain === ChainID.Bitcoin) {
        tx_hash = ticket.tx_hash;
        txHashType = TxHashType.Dst;
      }
    } else if (ticket?.action === TicketAction.Redeem) {
      if (
        [ChainID.Bitcoin, ChainID.BitcoinBrc20, ChainID.BitcoinckBTC].includes(
          ticket.dst_chain as ChainID,
        )
      ) {
        tx_hash = ticket.tx_hash;
        txHashType = TxHashType.Dst;
      }
    }
    if (tx_hash) {
      checkConfirmations(tx_hash).then((confirmations) => {
        setConfirmation({ confirmations, txHashType });
      });
    }
  }, [ticket?.ticket_id]);

  const confirmed = confirmation ? (
    <Box bg="green.400" color="black" fontSize={14} px={2}>
      <Text>{confirmation.confirmations} confirmations</Text>
    </Box>
  ) : null;

  const protocol = ticket ? getAssetProtocol(ticket) : "";

  return (
    <VStack w="100%" gap={8} pt={8} alignItems="center">
      <Helmet>
        <title>Ticket {ticketId} | Omnity Hub Explorer</title>
      </Helmet>
      {ticket && <TicketProgress ticket={ticket} />}
      {!isLoading && !ticket ? (
        <VStack py={32}>
          <Text fontSize={80} fontWeight={600}>
            404
          </Text>
          <VStack fontSize={24} gap={1}>
            <Text color="red.400">Invalid Ticket ID</Text>
            <Link to="/">
              <Text color="blue.400">GO HOME</Text>
            </Link>
          </VStack>
        </VStack>
      ) : (
        <VStack
          gap={2}
          borderWidth={2}
          p={{ base: 2, md: 8 }}
          borderColor="gray.500"
          width={{ base: window.innerWidth - 40, md: "100%" }}
        >
          <KeyValuePair
            item={{
              key: "Ticket",
              value: (
                <Text
                  display={{ base: "block", md: "inline" }}
                  maxW={{ base: maxW, md: MD_WIDTH - 400 }}
                  isTruncated
                  fontSize={18}
                  fontFamily="monospace"
                  textAlign="left"
                >
                  {ticketId}
                </Text>
              ),
            }}
            horizontal
          />
          {ticket && (
            <>
              <KeyValuePair
                horizontal
                item={{
                  key: "Status",
                  value: <TicketStatusComp status={ticket.status} />,
                }}
              />
              <KeyValuePair
                horizontal
                item={{
                  key: "Type",
                  value: (
                    <Badge
                      fontSize={20}
                      colorScheme={TICKET_ACTION_COLORS[ticket.action]}
                    >
                      {TICKET_ACTION_LABEL[ticket.action]}
                    </Badge>
                  ),
                }}
              />
              <KeyValuePair
                horizontal
                item={{
                  key: "Token",
                  value: (
                    <Stack
                      flexDir={{ base: "column", md: "row" }}
                      alignItems={{ base: "flex-start", md: "center" }}
                      fontSize={20}
                    >
                      <HStack>
                        <Text className="number">{token?.balance}</Text>
                        <Text color="gray.400" fontSize={14} className="number">
                          (≈ $
                          {advancedReadableNumber(
                            Number(token?.balance) * Number(token?.price),
                          )}
                          )
                        </Text>
                      </HStack>
                      <HStack>
                        <Avatar
                          size="xs"
                          src={token?.icon}
                          name={token?.symbol}
                        />
                        <Link to={`/token/${beautifyTokenId(ticket.token)}`}>
                          <Text color="blue.400">
                            {beautifyTokenId(ticket.token)}
                          </Text>
                        </Link>
                      </HStack>
                    </Stack>
                  ),
                }}
              />

              <KeyValuePair
                horizontal
                item={{
                  key: "Protocol",
                  value: protocol && (
                    <Badge
                      fontSize={20}
                      colorScheme={PROTOCOL_COLORS[protocol]}
                    >
                      {protocol}
                    </Badge>
                  ),
                }}
              />
              <Divider bg="gray.500" />

              <KeyValuePair
                horizontal
                item={{
                  key: "From",
                  value: ticket.sender ? (
                    <Link to={`/address/${beautifyAddress(ticket.sender)}`}>
                      <Text
                        fontSize={20}
                        maxW={maxW}
                        color="blue.400"
                        textAlign="left"
                        fontFamily="monospace"
                      >
                        {beautifyAddress(ticket.sender)}
                      </Text>
                    </Link>
                  ) : (
                    "-"
                  ),
                }}
              />
              {TicketAction.Burn !== ticket.action && (
                <KeyValuePair
                  horizontal
                  item={{
                    key: "To",
                    value: (
                      <Link to={`/address/${beautifyAddress(ticket.receiver)}`}>
                        <Text
                          fontSize={20}
                          maxW={maxW}
                          color="blue.400"
                          textAlign="left"
                          fontFamily="monospace"
                        >
                          {beautifyAddress(ticket.receiver)}
                        </Text>
                      </Link>
                    ),
                  }}
                />
              )}

              <KeyValuePair
                horizontal
                item={{
                  key: "Src Tx Hash",
                  value: (
                    <VStack alignItems="flex-start">
                      <HashLink
                        chain={ticket.src_chain}
                        hash={ticket.ticket_id?.trim()}
                        ellipsis={false}
                        fontSize={20}
                        isFinalized={ticket.status === TicketStatus.Finalized}
                        confirmation={
                          confirmation?.txHashType === TxHashType.Src
                            ? confirmation
                            : undefined
                        }
                      />
                      {confirmation &&
                        confirmation.txHashType === TxHashType.Src &&
                        confirmed}
                    </VStack>
                  ),
                }}
              />
              {ticket.intermediate_tx_hash && (
                <KeyValuePair
                  horizontal
                  item={{
                    key: "Intermediate Tx Hash",
                    value: (
                      <VStack alignItems="flex-start">
                        <HashLink
                          chain={ChainID.Bitcoin}
                          hash={ticket.intermediate_tx_hash?.trim()}
                          ellipsis={false}
                          fontSize={20}
                          isFinalized={ticket.status === TicketStatus.Finalized}
                          confirmation={
                            confirmation?.txHashType === TxHashType.Intermediate
                              ? confirmation
                              : undefined
                          }
                        />
                        {confirmation &&
                          confirmation.txHashType === TxHashType.Intermediate &&
                          confirmed}
                      </VStack>
                    ),
                  }}
                />
              )}
              <KeyValuePair
                horizontal
                item={{
                  key: "Dst Tx Hash",
                  value: (
                    <VStack alignItems="flex-start">
                      <HashLink
                        chain={
                          TicketAction.Mint === ticket.action
                            ? ticket.src_chain
                            : ticket.dst_chain
                        }
                        hash={ticket.tx_hash?.trim()}
                        ellipsis={false}
                        fontSize={20}
                        isFinalized={ticket.status === TicketStatus.Finalized}
                        confirmation={
                          confirmation?.txHashType === TxHashType.Dst
                            ? confirmation
                            : undefined
                        }
                      />
                      {confirmation &&
                        confirmation.txHashType === TxHashType.Dst &&
                        confirmed}
                    </VStack>
                  ),
                }}
              />
              <Divider bg="gray.500" />
              <KeyValuePair
                horizontal
                item={{
                  key: "Time",
                  value: dayjs(ticket.ticket_time / 1000000).format(
                    "YYYY, DD MMM, HH:mm:ss ",
                  ),
                }}
              />
              <KeyValuePair
                horizontal
                item={{
                  key: "Memo",
                  value:
                    ticket.memo &&
                    ticket.memo.startsWith("{") &&
                    ticket.memo.endsWith("}") ? (
                      <Box maxW={{ base: maxW, md: MD_WIDTH - 300 }}>
                        <ReactJson
                          src={JSON.parse(ticket.memo)}
                          theme="ocean"
                        />
                        {/* <JsonView
                          data={JSON.parse(ticket.memo)}
                          shouldExpandNode={allExpanded}
                          style={darkStyles}
                        /> */}
                      </Box>
                    ) : (
                      <Box
                        p={ticket.memo ? 2 : 0}
                        background={ticket.memo ? "gray.700" : "transparent"}
                        maxW={{ base: maxW, md: MD_WIDTH - 400 }}
                      >
                        <Text
                          textAlign="left"
                          fontFamily="monospace"
                          maxW={{ base: "100%", md: MD_WIDTH - 400 }}
                        >
                          {ticket.memo ?? "-"}
                        </Text>
                      </Box>
                    ),
                }}
              />
              <KeyValuePair
                horizontal
                isNumber
                item={{ key: "Seq", value: ticket.ticket_seq ?? "-" }}
              />
            </>
          )}
        </VStack>
      )}
    </VStack>
  );
}

function advancedReadableNumber(v: number) {
  if (v < 10) {
    return readableNumber(v, 4);
  } else if (v < 100) {
    return readableNumber(v, 2);
  } else {
    return readableNumber(v, 0);
  }
}

const ArrowRightUpIcon = chakra(ArrowUpRight);

function TicketProgress({ ticket }: { ticket?: TicketItem }) {
  if (
    !ticket ||
    ticket.action === TicketAction.Burn ||
    ticket.action === TicketAction.Mint
  ) {
    return null;
  }

  let srcBar = null;
  let dstBar = null;
  const w = { base: 50, md: 200 };
  switch (ticket.status) {
    case TicketStatus.Unknown:
    case TicketStatus.Pending:
      srcBar = <Progress w={w} size="xs" isIndeterminate />;
      dstBar = <Progress w={w} size="xs" isIndeterminate />;
      break;
    case TicketStatus.WaitingForConfirmBySrc:
      srcBar = <Progress w={w} size="xs" isIndeterminate />;
      dstBar = <Progress w={w} size="xs" isIndeterminate />;
      break;
    case TicketStatus.WaitingForConfirmByDest:
      srcBar = <Box w={w} h={1} bg="green.300" />;
      dstBar = <Progress w={w} size="xs" isIndeterminate />;
      break;
    case TicketStatus.Finalized:
      srcBar = <Box w={w} h={1} bg="green.300" />;
      dstBar = <Box w={w} h={1} bg="green.300" />;
      break;
  }
  let srcTxLink = "";
  let dstTxLink = "";
  if (ticket) {
    srcTxLink = ServiceFactory.getTicketIdLink({
      src_chain: ticket.src_chain as ChainID,
      ticket_id: ticket.ticket_id,
    });
    dstTxLink = ServiceFactory.getTicketIdLink({
      src_chain: ticket.dst_chain as ChainID,
      ticket_id: ticket.tx_hash,
    });
  }

  const hasTxHash = ticket.tx_hash?.trim() !== "";
  const hidden = hasTxHash && (ticket.tx_hash ?? "").trim() === "";
  return (
    <HStack gap={{ base: 2, md: 6 }} display={{ base: "none", md: "flex" }}>
      <VStack gap={1} scale={{ base: 0.5, md: 1 }}>
        <IChainLogo chain={ticket.src_chain as ChainID} size={56} />
        {ticket.ticket_id && (
          <Link to={srcTxLink} target={srcTxLink === "#" ? "" : "_blank"}>
            <HStack gap={0}>
              <Text
                maxW={{ base: 50, md: 120 }}
                fontFamily="monospace"
                isTruncated
                color="blue.400"
              >
                {ticket.ticket_id}
              </Text>
              {srcTxLink !== "#" && (
                <ArrowRightUpIcon size={20} color="blue.400" />
              )}
            </HStack>
          </Link>
        )}
      </VStack>
      {srcBar}
      <VStack pt={2}>
        <Image src={LOGO} alt="logo" h={{ base: 4, md: "42px" }} />
        <Text fontSize={20} fontWeight={600} className="color-anim">
          Omnity Hub
        </Text>
      </VStack>
      {dstBar}
      <VStack gap={1} scale={{ base: 0.5, md: 1 }}>
        <IChainLogo chain={ticket.dst_chain as ChainID} size={56} />
        <Link to={dstTxLink} target={dstTxLink === "#" ? "" : "_blank"}>
          <HStack gap={0}>
            {hasTxHash ? (
              <Text
                maxW={120}
                fontFamily="monospace"
                isTruncated
                color="blue.400"
              >
                {ticket.tx_hash}
              </Text>
            ) : (
              <HStack>
                <Spinner size="xs" />
                <Text color="gray.400" fontSize={12}>
                  Waiting
                </Text>
              </HStack>
            )}
            {hasTxHash && (
              <ArrowRightUpIcon
                size={20}
                color={hidden ? "transparent" : "blue.400"}
              />
            )}
          </HStack>
        </Link>
      </VStack>
    </HStack>
  );
}
