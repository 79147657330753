import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  HStack,
  Text,
  Avatar,
  Badge,
  VStack,
  chakra,
  TableCaption,
  Spinner,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { gql } from "graphql-request";
import { fetchGraph } from "../utils/graphFetch";
import { useEffect, useState } from "react";
import { TicketAction, TicketItem } from "src/types";
import TicketStatusComp from "./TicketStatusComp";
import { ChainID } from "@omnity/widget/src/types";
import { ArrowRight, ChevronRight } from "lucide-react";
import { TICKET_ACTION_COLORS, TICKET_ACTION_LABEL } from "src/utils/constants";
import { Link, useNavigate } from "react-router-dom";
import { useMetadata } from "src/contexts/metadata";
import IChainLogo from "./IChainLogo";
import { formatTickets } from "src/utils/format";

async function fetchLatestTickets() {
  try {
    const doc = gql`
      {
        ticket(order_by: { ticket_time: desc }, limit: 12) {
          action
          amount
          dst_chain
          memo
          receiver
          sender
          src_chain
          status
          ticket_id
          ticket_seq
          ticket_time
          ticket_type
          token
          tx_hash
        }
      }
    `;
    const data = await fetchGraph(doc, {});
    return formatTickets(data.ticket);
  } catch (error) {
    return [];
  }
}

const ChevronRightIcon = chakra(ChevronRight);

export default function LatestTickets() {
  const [tickets, setTickets] = useState<TicketItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { formatTokenAmount } = useMetadata();
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    fetchLatestTickets().then((res) => {
      setTickets(res);
      setIsLoading(false);
    });
  }, []);

  return (
    <VStack
      alignItems="flex-start"
      p={4}
      pl={{ base: 2, md: 8 }}
      py={8}
      gap={2}
      w={{ base: window.innerWidth, md: "67%" }}
      overflowX="scroll"
      bg="rgba(23, 28, 74, 1)"
    >
      <HStack w="100%" justifyContent="space-between">
        <Text fontSize="xl" fontWeight="bold">
          Latest Tickets
        </Text>
        <Link to="/tickets">
          <HStack gap={1} fontWeight={400} color="blue.400">
            <Text>MORE</Text>
            <ChevronRightIcon size={18} />
          </HStack>
        </Link>
      </HStack>
      <TableContainer w={{ base: "100%", md: "100%" }} gap={0}>
        <Table size="md" gap={0}>
          <Thead gap={0}>
            <Tr gap={0}>
              <Th>Status</Th>
              <Th>Chains</Th>
              <Th>Token</Th>
              <Th>Created</Th>
            </Tr>
          </Thead>
          <Tbody>
            {tickets.slice(0, 10).map((item) => {
              const token = formatTokenAmount?.(item.amount, item.token, false);
              return (
                <Tr
                  key={item.ticket_id}
                  cursor="pointer"
                  _hover={{ bg: "gray.700" }}
                  onClick={() => navigate(`/ticket/${item.ticket_id}`)}
                >
                  <Td px={0}>
                    <TicketStatusComp status={item.status} />
                  </Td>
                  <Td pr={0}>
                    <HStack>
                      <IChainLogo chain={item.src_chain as ChainID} size={24} />
                      {[
                        TicketAction.Transfer,
                        TicketAction.Redeem,
                        TicketAction.RedeemIcpChainKeyAssets,
                      ].includes(item.action) && (
                        <>
                          <ArrowRight color="white" size={18} strokeWidth={1} />
                          <IChainLogo
                            chain={item.dst_chain as ChainID}
                            size={24}
                          />
                        </>
                      )}
                    </HStack>
                  </Td>
                  <Td pr={0}>
                    <HStack>
                      <Badge colorScheme={TICKET_ACTION_COLORS[item.action]}>
                        {TICKET_ACTION_LABEL[item.action]}
                      </Badge>
                      <Text className="number">{token?.balance}</Text>
                      <Avatar
                        size="xs"
                        src={token?.icon}
                        name={token?.symbol}
                      />
                    </HStack>
                  </Td>
                  <Td color="gray.500" className="number">
                    {dayjs(item.ticket_time / 1000000).fromNow()}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {isLoading && <Spinner mt={8} size="lg" />}
      </TableContainer>
    </VStack>
  );
}
