import {
  Avatar,
  Badge,
  Divider,
  Grid,
  GridItem,
  HStack,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useParams, useSearchParams } from "react-router-dom";
import KeyValuePair from "src/components/KeyValuePair";
import { useMetadata } from "src/contexts/metadata";
import { completeTokenId, formatTickets } from "src/utils/format";
import { gql } from "graphql-request";
import { fetchGraph } from "../utils/graphFetch";
import { useEffect, useState } from "react";
import { TicketAction, TicketItem, TicketStatus } from "src/types";
import TicketTable from "src/components/TicketTable";
import { readableNumber } from "@omnity/widget/src/utils/format";
import { ChainID } from "@omnity/widget/src/types";
import TicketFilterChainId from "src/components/TicketFilterChainId";
import TicketFilterAction from "src/components/TicketFilterAction";
import TicketFilterStatus from "src/components/TicketFilterStatus";
import { Helmet } from "react-helmet";
import { PAGE_SIZE, PROTOCOL_COLORS } from "src/utils/constants";
import Pagination from "src/components/Pagination";
import useVolume from "src/hooks/useVolume";
import { RunesOnL2Pie } from "src/components/RunesOnL2Pie";
import TicketsOfRunesBar from "src/components/TicketsOfRunesBar";
import RuneContractIdTable from "src/components/RuneContractIdTable";
import { formatActionQuery } from "src/utils/helper";
import { getAssetProtocol } from "src/utils/chains";

async function fetchTicketsByTokenId(
  id: string,
  page: number = 1,
  chainId?: ChainID,
  action?: TicketAction,
  status?: TicketStatus,
) {
  try {
    const _chainId = chainId
      ? `_or: [
              { dst_chain: { _eq: "${chainId}" } }
              { src_chain: { _eq: "${chainId}" } }
            ]`
      : "";
    const _action = formatActionQuery(action);
    const _status = status ? `status: { _eq: "${status}" }` : "";
    const doc = gql`
      {
        ticket_aggregate(
          where: {
            token: { _eq: "${id}" }
            ${_action}
            ${_status}
            ${_chainId}
          }
        ) {
          aggregate {
            count
          }
        }
        ticket(
          order_by: {ticket_time: desc},
          where: {
            token: { _eq: "${id}" }
            ${_action}
            ${_status}
            ${_chainId}
          }
          limit: ${PAGE_SIZE}
          offset: ${(page - 1) * PAGE_SIZE}
        ) {
          action
          amount
          dst_chain
          memo
          receiver
          sender
          src_chain
          status
          ticket_id
          ticket_seq
          ticket_time
          ticket_type
          token
          tx_hash
        }
      }
    `;
    const data = await fetchGraph(doc, {});
    return {
      total: data.ticket_aggregate.aggregate.count,
      txs: formatTickets(data.ticket),
    };
  } catch (error) {
    return {
      total: 0,
      txs: [],
    };
  }
}

export default function Rune() {
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [txs, setTxs] = useState<TicketItem[]>([]);
  const { tokens } = useMetadata();
  const params = useParams();
  const id = params.token_id ?? "";
  const token_id = completeTokenId(id, tokens);
  const rune = tokens.find((t) => t.token_id === token_id);

  const [searchParams, setSearchParams] = useSearchParams();

  const _page = searchParams.get("page") ?? "1";
  const _chainId = searchParams.get("chainId");
  const _action = searchParams.get("action");
  const _status = searchParams.get("status");
  const [chainId, setChainId] = useState<ChainID | undefined>(
    _chainId as ChainID,
  );
  const [action, setAction] = useState<TicketAction | undefined>(
    _action as TicketAction,
  );
  const [status, setStatus] = useState<TicketStatus | undefined>(
    _status as TicketStatus,
  );

  const page = Number(_page) ?? 1;
  useEffect(() => {
    if (token_id) {
      fetchTicketsByTokenId(token_id, page, chainId, action, status).then(
        (res) => {
          setTxs(res.txs);
          setCount(res.total);
        },
      );
      const params: Record<string, string | string[]> = {};
      if (chainId) {
        params.chainId = chainId;
      }
      if (action) {
        params.action = action;
      }
      if (status) {
        params.status = status;
      }
      if (page) {
        params.page = String(page);
      }
      setSearchParams(params);
    }
  }, [token_id, chainId, action, status, page]);

  useEffect(() => {
    if (token_id) {
      fetchTicketsByTokenId(token_id).then((res) => {
        setTotal(res.total);
        setTxs(res.txs);
      });
    }
  }, [token_id]);

  const { runesVolumes } = useVolume();

  const volumeInUsd =
    runesVolumes.find((r) => r.token_id === rune?.token_id)?.volume ?? 0;

  const onClearAll = () => {
    setChainId(undefined);
    setAction(undefined);
    setStatus(undefined);
  };

  const protocol = rune ? getAssetProtocol({ token: rune.token_id }) : "";

  return (
    <VStack w="100%" alignItems="flex-start" gap={0}>
      <Helmet>
        <title>{id} | Omnity Hub Explorer</title>
      </Helmet>
      <HStack mb={4} pl={{ base: 4, md: 0 }}>
        <Avatar
          src={rune?.icon}
          name={rune?.name}
          size={{ base: "xl", md: "md" }}
          borderWidth={1}
          borderColor="gray.500"
        />
        <KeyValuePair
          item={{
            key: "Token",
            value: (
              <Stack
                flexDir={{ base: "column", md: "row" }}
                alignItems={{ base: "flex-start" }}
              >
                <Text fontSize={20} fontWeight={600}>
                  {id}
                </Text>
                {protocol && (
                  <Badge
                    colorScheme={
                      PROTOCOL_COLORS[protocol ?? "tokens"] ?? "gray"
                    }
                    fontSize={20}
                  >
                    {protocol}
                  </Badge>
                )}
                <Badge fontSize={20} colorScheme="blue">
                  {rune?.metadata.rune_id}
                </Badge>
              </Stack>
            ),
          }}
        />
      </HStack>

      <Grid
        w="100%"
        templateColumns={{
          base: `repeat(1, 1fr)`,
          md: `repeat(4, 1fr)`,
        }}
        borderWidth={1}
        borderColor="gray.500"
        borderLeftWidth={0}
        borderRightWidth={0}
      >
        <GridItem
          borderRightWidth={1}
          borderColor="gray.500"
          pl={{ base: 6, md: 0 }}
        >
          <KeyValuePair
            alignItems="flex-start"
            isNumber
            item={{
              key: "Price",
              value: rune?.price ? `$${rune?.price?.slice(0, 8)}` : "-",
            }}
            fontSize={24}
          />
        </GridItem>
        <GridItem
          borderRightWidth={1}
          borderColor="gray.500"
          pl={{ base: 6, md: 0 }}
        >
          <KeyValuePair
            alignItems="center"
            item={{
              key: "TVL",
              value: (
                <HStack gap={4}>
                  <Text fontSize={24} fontWeight={600} className="number">
                    ${readableNumber(volumeInUsd, 0)}
                  </Text>
                  <RunesOnL2Pie rune={rune} />
                </HStack>
              ),
            }}
          />
        </GridItem>
        <GridItem
          borderRightWidth={1}
          borderColor="gray.500"
          pl={{ base: 6, md: 0 }}
        >
          <KeyValuePair
            alignItems="center"
            isNumber
            item={{
              key: "Tickets",
              value: (
                <HStack>
                  <Text fontSize={24} fontWeight={600} className="number">
                    {readableNumber(total)}
                  </Text>
                  <TicketsOfRunesBar rune={rune} />
                </HStack>
              ),
            }}
            fontSize={24}
          />
        </GridItem>
        <GridItem pl={{ base: 6, md: 0 }}>
          <KeyValuePair
            alignItems="flex-end"
            isNumber
            item={{
              key: "Chains",
              value: (
                <HStack>
                  <Text fontSize={24} fontWeight={600} className="number">
                    {rune?.dst_chains.length}
                  </Text>
                  <RuneContractIdTable rune={rune} />
                </HStack>
              ),
            }}
            fontSize={24}
          />
        </GridItem>
      </Grid>

      <HStack
        w="100%"
        mt={8}
        pl={{ base: 4, md: 0 }}
        gap={2}
        color="gray.200"
        justifyContent="flex-end"
        flexDir={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
      >
        <TicketFilterChainId
          chainId={chainId}
          setChainId={setChainId}
          chainIds={(rune?.dst_chains ?? []) as ChainID[]}
        />
        <TicketFilterAction action={action} setAction={setAction} />
        <TicketFilterStatus status={status} setStatus={setStatus} />
        <Text color="blue.500" cursor="pointer" onClick={onClearAll}>
          CLEAR ALL
        </Text>
      </HStack>
      <Divider bg="gray.500" w="100%" h={1} mt={4} />
      <TicketTable items={txs} />
      <Pagination total={count} page={page} />
    </VStack>
  );
}
