import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  chakra,
  Box,
  Text,
} from "@chakra-ui/react";
import { useTheme } from "@nivo/core";
import { ResponsivePie } from "@nivo/pie";
import { formatUnits, readableNumber } from "@omnity/widget/src/utils/format";
import { PieChart } from "lucide-react";
import { useMemo } from "react";
import { TokenMeta } from "src/types";
import { getChainName } from "src/utils/chains";

const COLORS = [
  "hsl(94, 70%, 50%)",
  "hsl(185, 70%, 50%)",
  "hsl(42, 70%, 50%)",
  "hsl(51, 70%, 50%)",
  "hsl(243, 70%, 50%)",
  "hsl(143, 70%, 50%)",
  "hsl(2, 70%, 50%)",
];

const PieChartIcon = chakra(PieChart);

export function RunesOnL2Pie({ rune }: { rune?: TokenMeta }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const data = useMemo(() => {
    if (rune?.amount_on_l2?.length) {
      return rune.amount_on_l2.map((item) => {
        return {
          chainId: getChainName(item.chain_id) ?? "",
          amount: Math.ceil(
            Number(formatUnits(BigInt(item.amount), rune?.decimals)) *
              Number(rune.price),
          ),
        };
      });
    }
    return [];
  }, [rune?.amount_on_l2?.length, rune?.decimals]);

  const _data = data.map((d, idx) => ({
    id: d.chainId,
    label: d.chainId,
    value: d.amount,
    color: COLORS[idx % COLORS.length],
  }));

  const theme = useTheme();

  if (!rune) {
    return null;
  }

  const _theme = Object.assign(theme, {
    labels: {
      ...theme.labels,
      text: {
        ...theme.labels.text,
        fontSize: 20,
      },
    },
  });

  return (
    <>
      <PieChartIcon
        size={26}
        color="blue.400"
        cursor="pointer"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onOpen();
        }}
      />

      <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="#000433">
          <ModalHeader>
            <Text display="inline" color="blue.400">
              {rune.name}
            </Text>{" "}
            on L2s
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              // w={400}
              h={400}
              color="black"
              fontSize={18}
              fontFamily="Reddit Mono"
            >
              <ResponsivePie
                data={_data}
                theme={_theme}
                valueFormat={(value) => {
                  return `$${readableNumber(value, 0)}`;
                }}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 0.2]],
                }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#FFF"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: "color" }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                  from: "color",
                  modifiers: [["darker", 2]],
                }}
                defs={[
                  {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.3)",
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.3)",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
