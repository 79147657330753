import {
  Badge,
  Divider,
  Grid,
  GridItem,
  HStack,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useParams, useSearchParams } from "react-router-dom";
import KeyValuePair from "src/components/KeyValuePair";
import { useMetadata } from "src/contexts/metadata";
import { gql } from "graphql-request";
import { fetchGraph } from "../utils/graphFetch";
import { useEffect, useState } from "react";
import { TicketAction, TicketItem, TicketStatus } from "src/types";
import TicketTable from "src/components/TicketTable";
import { readableNumber } from "@omnity/widget/src/utils/format";
import { ChainID, ChainName } from "@omnity/widget/src/types";
import TicketFilterAction from "src/components/TicketFilterAction";
import TicketFilterStatus from "src/components/TicketFilterStatus";
import { Helmet } from "react-helmet";
import { PAGE_SIZE } from "src/utils/constants";
import Pagination from "src/components/Pagination";
import useVolume from "src/hooks/useVolume";
import TicketFilterTokenId from "src/components/TicketFilterTokenId";
import IChainLogo from "src/components/IChainLogo";
import { extGetChainIdFromName } from "src/utils/chains";
import { formatActionQuery } from "src/utils/helper";
import { formatTickets } from "src/utils/format";

async function fetchTicketsByChainId(
  ids: string[],
  page: number = 1,
  tokenId?: string,
  action?: TicketAction,
  status?: TicketStatus,
) {
  try {
    const _tokenId = tokenId ? `token: { _eq: "${tokenId}" }` : "";
    const _action = formatActionQuery(action);
    const _status = status ? `status: { _eq: "${status}" }` : "";
    const _srcIds = ids
      .map((id) => `{ src_chain: { _eq: "${id}" } }`)
      .join("\n");
    const _dstIds = ids
      .map((id) => `{ dst_chain: { _eq: "${id}" } }`)
      .join("\n");

    const doc = gql`
      {
        ticket_aggregate(
          where: {
            _or: [
              ${_srcIds}
              ${_dstIds}
            ]
            ${_action}
            ${_status}
            ${_tokenId}
          }
        ) {
          aggregate {
            count
          }
        }
        ticket(
          order_by: {ticket_time: desc},
          where: {
            _or: [
              ${_srcIds}
              ${_dstIds}
            ]
            ${_action}
            ${_status}
            ${_tokenId}
          }
          limit: ${PAGE_SIZE}
          offset: ${(page - 1) * PAGE_SIZE}
        ) {
          action
          amount
          dst_chain
          memo
          receiver
          sender
          src_chain
          status
          ticket_id
          ticket_seq
          ticket_time
          ticket_type
          token
          tx_hash
        }
      }
    `;
    const data = await fetchGraph(doc, {});
    return {
      total: data.ticket_aggregate.aggregate.count,
      txs: formatTickets(data.ticket),
    };
  } catch (error) {
    return {
      total: 0,
      txs: [],
    };
  }
}

export default function Chain() {
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [txs, setTxs] = useState<TicketItem[]>([]);
  const { tokens, chains } = useMetadata();
  const params = useParams();
  const chainName = params.chain_name ?? "";
  const chainIds = extGetChainIdFromName(chainName);
  const filteredChains = chains.filter((chain) =>
    chainIds.includes(chain.chain_id as ChainID),
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const _page = searchParams.get("page") ?? "1";
  const _tokenId = searchParams.get("tokenId");
  const _action = searchParams.get("action");
  const _status = searchParams.get("status");
  const [tokenId, setTokenId] = useState<string | undefined>(_tokenId ?? "");
  const [action, setAction] = useState<TicketAction | undefined>(
    _action as TicketAction,
  );
  const [status, setStatus] = useState<TicketStatus | undefined>(
    _status as TicketStatus,
  );

  const page = Number(_page) ?? 1;
  useEffect(() => {
    if (chainIds.length) {
      fetchTicketsByChainId(chainIds, page, tokenId, action, status).then(
        (res) => {
          setTxs(res.txs);
          setCount(res.total);
        },
      );
      const params: Record<string, string | string[]> = {};
      if (tokenId) {
        params.tokenId = tokenId;
      }
      if (action) {
        params.action = action;
      }
      if (status) {
        params.status = status;
      }
      if (page) {
        params.page = String(page);
      }
      setSearchParams(params);
    }
  }, [chainIds[0], action, status, page]);

  useEffect(() => {
    if (chainIds.length) {
      fetchTicketsByChainId(chainIds).then((res) => {
        setTxs(res.txs);
        setTotal(res.total);
      });
    }
  }, [chainIds[0]]);

  const tokenIds = tokens
    .filter((t) => t.dst_chains.some((c) => chainIds.includes(c)))
    .map((t) => t.token_id);

  const { chainVolumes } = useVolume();
  const volumeInUsd = chainVolumes.find((c) =>
    chainIds.includes(c.chain_id),
  )?.volume;

  const onClearAll = () => {
    setTokenId(undefined);
    setAction(undefined);
    setStatus(undefined);
  };

  const counterpartiesLength = new Set(
    filteredChains.map((chain) => chain.counterparties).flat(),
  ).size;

  return (
    <VStack w="100%" alignItems="flex-start" gap={0}>
      <Helmet>
        <title>{chainName} | Omnity Hub Explorer</title>
      </Helmet>
      <HStack mb={4} pl={{ base: 4, md: 0 }}>
        <IChainLogo chain={chainName as ChainName} size={48} />
        <KeyValuePair
          item={{
            key: "Chain",
            value: (
              <Stack
                flexDir={{ base: "column", md: "row" }}
                alignItems={{ base: "flex-start" }}
              >
                <Text fontSize={20} fontWeight={600}>
                  {chainName}
                </Text>
                {filteredChains.map((chain) => (
                  <Badge key={chain.chain_id} fontSize={20} colorScheme="blue">
                    {chain?.chain_type}
                  </Badge>
                ))}
              </Stack>
            ),
          }}
        />
      </HStack>

      <Grid
        w="100%"
        templateColumns={{
          base: `repeat(1, 1fr)`,
          md: `repeat(4, 1fr)`,
        }}
        borderWidth={1}
        borderColor="gray.500"
        borderLeftWidth={0}
        borderRightWidth={0}
      >
        <GridItem borderRightWidth={1} borderColor="gray.500">
          <KeyValuePair
            alignItems="flex-start"
            item={{
              key: "TVL",
              value: (
                <HStack gap={4}>
                  <Text fontSize={24} fontWeight={600} className="number">
                    ${readableNumber(volumeInUsd, 0)}
                  </Text>
                  {/* <RunesOnChainPie chainId={chainId} /> */}
                </HStack>
              ),
            }}
          />
        </GridItem>
        <GridItem borderRightWidth={1} borderColor="gray.500">
          <KeyValuePair
            alignItems="center"
            isNumber
            item={{
              key: "Tickets",
              value: (
                <HStack>
                  <Text fontSize={24} fontWeight={600} className="number">
                    {readableNumber(total)}
                  </Text>
                </HStack>
              ),
            }}
            fontSize={24}
          />
        </GridItem>
        <GridItem borderRightWidth={1} borderColor="gray.500">
          <KeyValuePair
            alignItems="center"
            isNumber
            item={{
              key: "Counterparties",
              value: counterpartiesLength,
            }}
            fontSize={24}
          />
        </GridItem>
        <GridItem>
          <KeyValuePair
            alignItems="flex-end"
            isNumber
            item={{
              key: "Tokens",
              value: tokenIds.length,
            }}
            fontSize={24}
          />
        </GridItem>
      </Grid>

      <HStack
        w="100%"
        mt={8}
        pl={{ base: 4, md: 0 }}
        gap={2}
        color="gray.200"
        justifyContent="flex-end"
        flexDir={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
      >
        <TicketFilterTokenId
          tokenId={tokenId}
          setTokenId={setTokenId}
          tokenIds={tokenIds}
        />
        <TicketFilterAction action={action} setAction={setAction} />
        <TicketFilterStatus status={status} setStatus={setStatus} />
        <Text color="blue.500" cursor="pointer" onClick={onClearAll}>
          CLEAR ALL
        </Text>
      </HStack>
      <Divider bg="gray.500" w="100%" h={1} mt={4} />
      <TicketTable items={txs} />
      <Pagination total={count} page={page} />
    </VStack>
  );
}
